import { template as template_c95a39f94bb84c3bbfa9f0bb68446104 } from "@ember/template-compiler";
const FKControlMenuContainer = template_c95a39f94bb84c3bbfa9f0bb68446104(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
