import { template as template_9d11fda8daf547b59911005ebc3c5988 } from "@ember/template-compiler";
const FKLabel = template_9d11fda8daf547b59911005ebc3c5988(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
