import { template as template_bec0dfdbfb95408e95651c47a3585065 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_bec0dfdbfb95408e95651c47a3585065(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
