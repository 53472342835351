import { template as template_95c883121a1a47daa959b439309b75aa } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_95c883121a1a47daa959b439309b75aa(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
