import { template as template_a97f1e5cd3054a7e8adfae2f1b32b781 } from "@ember/template-compiler";
const WelcomeHeader = template_a97f1e5cd3054a7e8adfae2f1b32b781(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
